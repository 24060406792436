import { useEffect , useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.scss';

const Home = () => {
    const navigate = useNavigate();
    const rowStyle = { cursor: "pointer"};

    return <div>
    <h1 className="u-margin-top-xl">MIA Engine</h1>
    <h2 className="u-margin-top-xl">Maak een keuze</h2>

    <br/><br/><br/><br/>
    <ul className="a-list a-list--lined">
    <li className="a-list__item">
      <a className="a-avatar-list__item-container">
        <div className="a-avatar-list__content">
          <span
            className="a-list__text"
            style={rowStyle}
            onClick={() => {
              navigate("/questions/HELPSEEKER_QUESTIONS");
            }}
          >
            Hulpvrager vragen
          </span>
        </div>
      </a>
    </li>
    <li className="a-list__item">
      <a className="a-avatar-list__item-container">
        <div className="a-avatar-list__content">
          <span
            className="a-list__text"
            style={rowStyle}
            onClick={() => {
              navigate("/questions/THERAPIST_QUESTIONS");
            }}
          >
            Psycho-therapeut vragen
          </span>
        </div>
      </a>
    </li>
    <li className="a-list__item">
      <a className="a-avatar-list__item-container">
        <div className="a-avatar-list__content">
          <span
            className="a-list__text"
            style={rowStyle}
            onClick={() => {
              navigate("/questions/GENERAL_PRACTITIONER_QUESTIONS");
            }}
          >
            Huisarts vragen
          </span>
        </div>
      </a>
    </li>
    <li className="a-list__item">
      <a className="a-avatar-list__item-container">
        <div className="a-avatar-list__content">
          <span
            className="a-list__text"
            style={rowStyle}
            onClick={() => {
              navigate("/questions/EMPLOYER_QUESTIONS");
            }}
          >
            Werkgever vragen
          </span>
        </div>
      </a>
    </li>
    <li className="a-list__item">
        <a className="a-avatar-list__item-container">

          <div className="a-avatar-list__content">
            
            <span className="a-list__text" style={rowStyle} onClick={() => {navigate("/providers/");}}>
                Hulpverleners  
            </span>
            
          </div>
        </a>

    </li>
   
   </ul><br/><br/><br/><br/><br/>
    
    </div>
};

export default Home;

