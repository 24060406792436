import { useEffect , useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Questions.scss';
import { useAuth0 } from "@auth0/auth0-react";
import questionService from '../../services/question';
import categoryService from '../../services/category';
import QuestionDetails from './QuestionDetails';

const Questions = () => {
    const navigate = useNavigate();
    const { questionnaireType } = useParams(); // Get questionnaireType from the URL
  

    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [questions, setQuestions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [isDeleteConfirmActice, setIsDeleteConfirmActice] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [questionToDelete, setQuestionToDelete] = useState({});
    const rowStyle = { cursor: "pointer"};
    const onSubmitQuestion = async (question) => {
        let newQuestions = [];
        if(!question.id) {
            let newQuestion = (await questionService.insert(getAccessTokenSilently, getAccessTokenWithPopup, question)).data;
            newQuestions = [...questions, newQuestion];
        }
        else {
          await questionService.update(getAccessTokenSilently, getAccessTokenWithPopup, question);
          newQuestions = questions.map(q => {
            return (q.id === question.id)? question : q;
          });
        }
        setQuestions(newQuestions);

        let newCategories = [...categories];
        newCategories.map((category, index) => {
          category.questions = newQuestions.filter((question) => question.categoryId === category.id);
          category.questions = category.questions.sort((a,b) => {return a.order - b.order});
        });
        setCategories(newCategories);

        setIsEditActive(false);
    }

    const onCancelQuestionEdit = function() {
      setIsEditActive(false);
    }

    const onDeleteButtonClicked = async () => {
      await questionService.delete(getAccessTokenSilently, getAccessTokenWithPopup, questionToDelete.id);
      let newQuestions = questions.filter((q) => q.id !== questionToDelete.id);
      setQuestions(newQuestions);

      let newCategories = [...categories];
      newCategories.map((category, index) => {
        category.questions = newQuestions.filter((question) => question.categoryId === category.id);
        category.questions = category.questions.sort((a,b) => {return a.order - b.order});
      });
      setCategories(newCategories);

      setIsDeleteConfirmActice(false);
      
    }

    const fetchCategoriesAndQuestions = async () => {
      try {
        const theQuestions = await questionService.getAllRootQuestions(
          getAccessTokenSilently,
          getAccessTokenWithPopup
        );
        const theCategories = await categoryService.getQuestionCategories(
          getAccessTokenSilently,
          getAccessTokenWithPopup,
          questionnaireType // Pass questionnaireType to filter
        );
  
        theCategories.map((category) => {
          category.questions = theQuestions.filter(
            (question) => question.categoryId === category.id
          );
          category.questions = category.questions.sort((a, b) => a.order - b.order);
        });
  
        setCategories(theCategories);
        setQuestions(theQuestions);
      } catch (error) {
        console.error("Error fetching categories or questions:", error);
      }
    };
  
    useEffect(() => {
      fetchCategoriesAndQuestions();
    }, [questionnaireType]); // Re-fetch when questionnaireType changes
  


    return <div>
    <h2 className="u-margin-top-xl"><a href="#"><i className="fa-solid fa-arrow-left" onClick={() => {navigate('/')}}></i></a>&nbsp;&nbsp;Hoofdvragen</h2>

    <br/>
    <ul className="a-list a-list--lined">
    {(categories && categories.length)? categories.map((category, categoryIndex) => (  
      <div key={categoryIndex}>
      <h3 className="u-margin-top-xl">{category.name}</h3>
      <button className="a-button has-icon-left" style={{float: 'right'}} onClick={() => {setIsEditActive(true); setSelectedQuestion({hidden:false, categoryId:category.id});}}>
        <span className="ai" aria-hidden="true"><i className="fa-solid fa-plus"></i></span>
        Nieuwe vraag
      </button>
      <br/><br/><br/>
      {(category.questions && category.questions.length)? category.questions.map((question, index) => (  
                    <li key={index} className="a-list__item">
                      <a className="a-avatar-list__item-container">

                        <div className="a-avatar-list__content">
                          <span className="a-list__text" style={rowStyle} onClick={() => {navigate("/question/" + question.id);}}>
                            <span className="avatar-list__name paragraph">{question.id}</span>
                            <br/>
                            <span className="small">{question.description}</span>
                          </span>
                          <button className="a-button a-button--s has-icon a-button--outlined" aria-label="Aanpassen" onClick={() => {setIsEditActive(true); setSelectedQuestion(question);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-pen"></i></span>
                          </button>&nbsp;
                          <button className="a-button a-button--s has-icon" aria-label="Navigeer naar vraag" onClick={() => {navigate("/question/" + question.id);}}>
                            <span className="ai" aria-hidden="true"><i className="fa-solid fa-arrow-right"></i></span>
                          </button>&nbsp;
                          <span className="ai" aria-hidden="true" style={{color:'#a00'}}><i className="fa-solid fa-trash" onClick={() => {setIsDeleteConfirmActice(true); setQuestionToDelete(question);}}></i></span>
                        </div>
                      </a>
      
                </li>
              )):null}  
   </div>)):null}
   </ul><br/><br/><br/><br/><br/>
    
   {isDeleteConfirmActice && 
      <div className="m-modal modal-position" role="dialog" aria-modal="true" aria-labelledby="myModalTitle" aria-describedby="myModelDesc">
        <div className="m-modal__content">
          <div className="m-modal__header u-margin-bottom-xs">
            <button className="m-modal__close a-button a-button--text a-button--neutral has-icon" aria-label="Close" onClick={() => {setIsDeleteConfirmActice(false)}}><span className="ai" aria-hidden="true"><i className="fa-solid fa-x"></i></span></button>
            <h4 id="myModalTitle" className="h6">Opgepast</h4>
          </div>
          <div className="u-margin-bottom">
            <p id="myModalDesc">Ben je zeker dat je deze vraag wil verwijderen? Denk eraan dat alle scores die hiermee verbonden zijn zullen verdwijnen!</p>
          </div>
          <div className="m-modal__footer">
            <button className="a-button a-button--danger" onClick={onDeleteButtonClicked}>Ja, ik wil ze verwijderen</button>
            <button className="a-button a-button--outlined" onClick={() => {setIsDeleteConfirmActice(false)}}>Cancel</button>
          </div>
        </div>
      </div>
    }
    <div className={isEditActive ? 'm-pane m-pane--right m-pane--open' : 'm-pane m-pane--right'}>

    {isEditActive && 
      <QuestionDetails onSubmit={onSubmitQuestion} onCancel={onCancelQuestionEdit} {...{selectedQuestion}}/>
    }
    </div>
    <div className={isEditActive ? 'm-overlay m-overlay__pane is-active' : 'm-overlay m-overlay__pane'} onClick={() => setIsEditActive(false)} role="button"><span className="u-screen-reader-only">Close pane</span></div>
     
    </div>
};

export default Questions;

