import getAccessToken from "./accessTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../Config";

const categoryService = {
    getQuestionCategories: async function(getAccessTokenSilently, getAccessTokenWithPopup, questionnaireType) {
        try {
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
    
            // Append the questionnaireType directly in the URL path
            const url = Config.getBackendUrl() + `/categories/type/question/${encodeURIComponent(questionnaireType)}`;
    
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            return await response.json();
        } catch (ex) {
            console.log(ex.message);
        }
    },    
    
    getMethodCategories: async function(getAccessTokenSilently, getAccessTokenWithPopup) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/type/method`;

            const response = await fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getOrganisationCategories: async function(getAccessTokenSilently, getAccessTokenWithPopup) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/type/organisation`;

            const response = await fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getMediaCategories: async function(getAccessTokenSilently, getAccessTokenWithPopup) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/type/media`;

            const response = await fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getProviderCategories: async function(getAccessTokenSilently, getAccessTokenWithPopup) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/type/provider`;

            const response = await fetch(url, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getMethodsForCategory: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id + '/methods', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getOrganisationsForCategory: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id + '/organisations', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getMediasForCategory: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id + '/medias', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    getQuestionsForCategory: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id + '/questions', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    get: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id , {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    insert: async function(getAccessTokenSilently, getAccessTokenWithPopup, category) {
        try {

            category.id = uuidv4();
            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(category)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    update: async function(getAccessTokenSilently, getAccessTokenWithPopup, category) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(category)
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    },
    delete: async function(getAccessTokenSilently, getAccessTokenWithPopup, id) {
        try {

            let accessToken = await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup);
            const url = Config.getBackendUrl() + `/categories/`;

            const response = await fetch(url + id, {
                method: 'DELETE',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            });

            return await response.json();
        }
        catch(ex) {
            console.log(ex.message);
        }
    }
}

export default categoryService;